import React from "react";
import "../styles/ProfileCard.css";

function Button(props) {
  return (
    <a href={props.redir} className="btn btn3">
      {props.name}
    </a>
  );
}

function ProfileCard() {
  return (
    <div className="profile-card">
      <div className="profile-img-container">
        <img
          className="profile-img"
          src="https://res.cloudinary.com/nilay-patel-salesforce/image/upload/v1664368943/Personal%20Site/Images/Avatar/avatar_photo_wkniss.jpg"
          alt="Avatar"
        />
      </div>
      <div className="profile-info">
        <h1>Nilay Patel</h1>
        <h2>Technical Specialist @ Microsoft</h2>
        <a className="coffee-button" href="https://www.buymeacoffee.com/Nilay">
          <img
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=Nilay&button_colour=493627&font_colour=ffffff&font_family=Cookie&outline_colour=ffffff&coffee_colour=DDCA7D"
            alt="Buy me a coffee"
          />
        </a>
        <div className="social-buttons">
          <Button name="Twitter" redir="https://twitter.com/nilayvekaria" />
          <Button
            name="LinkedIn"
            redir="https://www.linkedin.com/in/nilay-patel-profile"
          />
          <Button name="Github" redir="https://www.github.com/patelnilay" />
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
