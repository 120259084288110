import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import ProfileCard from "./components/ProfileCard";
import CertificateFlow from "./components/CertificateFlow";
import ProjectsCard from "./components/ProjectsCard";

function App() {
  return (
    <div className="app-container">
      <ProfileCard />
      <CertificateFlow />
      <ProjectsCard />
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
