import React from "react";
import "../styles/ProjectsCard.css";
import gif from "../media/stay_tuned.gif";

function ProjectsCard() {
  return (
    <div className="projects-card">
      <h2>Project showcase coming soon! 😊😇</h2>
      <img className="coming-soon-gif" src={gif} alt="Coming Soon" />
      <h3>👷 They're still under construction 🛠️</h3>
    </div>
  );
}

export default ProjectsCard;
