import React, { useEffect, useRef, useState } from "react";
import "../styles/CertificateFlow.css";
import adminCertImg from "../media/adminCertImg.png";
import advAdmin from "../media/advAdmin.png";
import appBuildCertImg from "../media/appBuildCertImg.png";
import msFundamentals from "../media/MS-fundamentals-badge.svg";

const certificates = [
  { src: adminCertImg, alt: "Admin Cert Img", title: "Admin Certification" },
  {
    src: appBuildCertImg,
    alt: "App Builder Img",
    title: "App Builder Certification",
  },
  {
    src: advAdmin,
    alt: "Advanced Admin Img",
    title: "Advanced Admin Certification",
  },
  {
    src: msFundamentals,
    alt: "Microsoft Fundamentals Img",
    title: "Power Platform Fundamentals",
  },
];

function CertificateFlow() {
  const carouselRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const isDesktop = window.innerWidth > 768;

    if (isDesktop) {
      const carousel = carouselRef.current;

      const duplicateCertificates = () => {
        const items = Array.from(carousel.children);
        items.forEach((item) => {
          const clone = item.cloneNode(true);
          carousel.appendChild(clone);
        });
      };

      duplicateCertificates();

      let scrollInterval;
      const startScrolling = () => {
        scrollInterval = setInterval(() => {
          if (!isPaused && carousel) {
            carousel.scrollLeft += 1; // Smooth continuous scrolling
          }
        }, 10);
      };

      const stopScrolling = () => {
        clearInterval(scrollInterval);
      };

      const resetScroll = () => {
        if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
          carousel.scrollLeft = 0;
        }
      };

      carousel.addEventListener("scroll", resetScroll);
      startScrolling();

      return () => {
        stopScrolling();
        carousel.removeEventListener("scroll", resetScroll);
      };
    }
  }, [isPaused]);

  const scrollLeft = () => {
    if (carouselRef.current) {
      setIsPaused(true);
      carouselRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
      setTimeout(() => setIsPaused(false), 2000); // Resume automatic scrolling after 2 seconds
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      setIsPaused(true);
      carouselRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
      setTimeout(() => setIsPaused(false), 2000); // Resume automatic scrolling after 2 seconds
    }
  };

  return (
    <div
      className="certificate-card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h2>My Certifications</h2>
      <div
        className="certificate-carousel"
        ref={carouselRef}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        {certificates.map((cert, index) => (
          <div key={index} className="certificate-item">
            <img src={cert.src} alt={cert.alt} />
            <p>{cert.title}</p>
          </div>
        ))}
      </div>
      {isHovered && window.innerWidth > 768 && (
        <>
          <button className="arrow left-arrow" onClick={scrollLeft}>
            &#10094;
          </button>
          <button className="arrow right-arrow" onClick={scrollRight}>
            &#10095;
          </button>
        </>
      )}
    </div>
  );
}

export default CertificateFlow;
